import { useState } from 'react';

import ClickAwayListener from '@mui/base/ClickAwayListener';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  Grid,
  Rating,
  Tooltip,
  Typography,
  Modal,
  IconButton,
} from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';

import CustomInfoIcon from 'src/asserts/CustomInfo';
import { getTariffsRating } from 'src/store/thunks/contribution.thunk';
import { getTextColor } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/utils/hooks';

import mandants from '../config/mandant';

export const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

interface Props {
  title: string[];
  isHeader?: boolean;
  isActive?: boolean;
  iconFlag?: boolean;
  tarrifId?: string;
  personIdx?: number;
}

export default function TooltipModalReusable({
  title,
  isActive,
  isHeader,
  iconFlag = true,
  tarrifId,
  personIdx,
}: Props) {
  const [show, setShow] = useState(false);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal
  const { mandant } = useAppSelector((state) => state.config);
  const persons = useAppSelector((state) => state.contribution.persons);
  const dispatch = useAppDispatch();

  type Mandant = keyof typeof mandants;
  const allowRattings: boolean = mandants[mandant as Mandant].allowRattings;

  const ratingValues: RatingValues = {
    premium: 5,
    comfort: 4,
    base: 3,
    none: 0,
  };

  const ratingText = {
    premium: 'Premium',
    comfort: 'Komfort',
    base: 'Basis',
    none: 'Nicht bewertet',
  };

  const handleOpenModal = () => {
    setModalOpen(true);
    if (
      tarrifId &&
      personIdx !== undefined &&
      persons[personIdx].ratings[tarrifId] === null
    ) {
      dispatch(getTariffsRating({ tarrifId, mandant, personIdx }));
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setShow(false);
      }}
    >
      <>
        <CustomWidthTooltip
          title="Bitte klicken, um Tarifdetails anzuzeigen"
          enterTouchDelay={100}
          open={show}
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <Button
            disableRipple
            onClick={handleOpenModal}
            sx={{
              minWidth: '0',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            {iconFlag ? (
              isHeader ? (
                <Box position={'relative'} zIndex={2} top={'3px'}>
                  <Box
                    bgcolor={getTextColor(mandant)}
                    position={'absolute'}
                    top={'3px'}
                    left={'3px'}
                    sx={{
                      height: '15px',
                      width: '15px',
                      zIndex: -1,
                      borderRadius: '50%',
                    }}
                  ></Box>
                  <CustomInfoIcon
                    sx={{
                      color: (theme) => {
                        const customInfoIconColor =
                          theme.palette?.customInfoIconColor;
                        if (customInfoIconColor) {
                          if (isActive) {
                            return customInfoIconColor.main;
                          } else if (isHeader) {
                            return customInfoIconColor.main;
                          }
                        } else {
                          return 'secondary.main';
                        }
                      },
                    }}
                    fill={(theme: Theme) => {
                      const customInfoIconColor =
                        theme.palette?.customInfoIconColor;
                      if (customInfoIconColor) {
                        if (isActive) {
                          return customInfoIconColor.text;
                        } else if (isHeader) {
                          return customInfoIconColor?.textPersonHeader;
                        }
                      } else {
                        return 'white';
                      }
                    }}
                  />
                </Box>
              ) : (
                <CustomInfoIcon
                  sx={{
                    color: (theme) => {
                      const customInfoIconColor =
                        theme.palette?.customInfoIconColor;
                      if (customInfoIconColor) {
                        if (isActive) {
                          return customInfoIconColor.main;
                        } else if (isHeader) {
                          return 'primary.main';
                        }
                      } else {
                        return 'primary.main';
                      }
                    },
                  }}
                  fill={(theme: Theme) => {
                    const customInfoIconColor =
                      theme.palette?.customInfoIconColor;
                    if (customInfoIconColor) {
                      if (isActive) {
                        return customInfoIconColor.text;
                      } else if (isHeader) {
                        return customInfoIconColor?.textPersonHeader;
                      } else {
                        return 'white';
                      }
                    } else {
                      return 'white';
                    }
                  }}
                />
              )
            ) : (
              <WarningAmberIcon color="warning" />
            )}
          </Button>
        </CustomWidthTooltip>

        {/* Modal for displaying tariff details */}
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography id="modal-title" variant="h6">
                Tarifqualität
              </Typography>
              <IconButton
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  top: '16px',
                  right: '16px',
                  color: 'gray',
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              {personIdx !== undefined &&
                tarrifId &&
                persons[personIdx].ratings[tarrifId] !== null &&
                persons[personIdx]?.ratings[tarrifId]?.map(
                  (rating: any, index: number) => (
                    <Grid container key={index}>
                      <Grid item xs={5} sm={7}>
                        {rating?.questionSmallTitle}
                      </Grid>
                      <Grid item xs={4} sm={3}>
                        <Rating
                          name="read-only"
                          readOnly
                          value={
                            ratingValues[
                              rating?.selectedAnswer
                                ?.answerId as keyof RatingValues
                            ]
                          }
                        />
                      </Grid>
                      <Grid item xs={3} sm={2}>
                        {
                          ratingText[
                            rating?.selectedAnswer
                              ?.answerId as keyof RatingValues
                          ]
                        }
                      </Grid>
                    </Grid>
                  )
                )}
            </Box>
            <Box>
              <Typography variant="h6" color={'primary'} paddingTop={1}>
                Leistungskurzbeschreibung
              </Typography>
              <ul style={{ padding: '0' }}>
                {title.map((item, index) => {
                  const htmlStr = item
                    .replace(/\n/g, '<br>')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#039;');
                  return (
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: htmlStr }}
                    ></div>
                  );
                })}
              </ul>
            </Box>
          </Box>
        </Modal>
      </>
    </ClickAwayListener>
  );
}
