import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/de'; // Import the German locale for dayjs
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useField } from 'formik';

import { TextField, FormControl, Grid } from '@mui/material';
import {
  LocalizationProvider,
  DatePicker,
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers';

import QuestionLayout from './QuestionLayout';
import TooltipReusable from 'src/components/TooltipReusable';
import { useAppSelector } from 'src/utils/hooks';
import mandants from 'src/config/mandant';

dayjs.locale('de');

interface Props {
  question: Question;
  answer?: string | undefined;
}

export default function DateQuestion({ question, answer }: Props) {
  const [field, meta, helper] = useField(
    question.key.replace(/\./g, '&').replace(/\[\]/g, '$')
  );

  const { mandant } = useAppSelector((state) => state.config);
  type Mandant = keyof typeof mandants;
  const birthdateValidation: boolean =
    mandants[mandant as Mandant]?.birthdateValidation;

  const [isDateValid, setIsDateValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (question.readonly) {
      const currentDate = dayjs();
      if (answer) {
        helper.setValue(dayjs(answer).format('YYYY-MM-DD'));
      } else {
        helper.setValue(currentDate?.format('YYYY-MM-DD'));
      }
    } else {
      helper.setValue(null);
    }
  }, []);

  const handleDateChange = (newValue: Dayjs | null) => {
    const data = newValue?.format('YYYY-MM-DD');
    helper.setValue(data ?? newValue);

    const birthdate = localStorage.getItem('birthdate');
    const policyHolderIsEqualInsuredPerson = localStorage.getItem(
      'policyHolderIsEqualInsuredPerson'
    );
    const minAge = 18;

    setIsDateValid(true);
    setErrorMessage('');

    if (
      birthdateValidation &&
      question.key === 'policyHolderBirthday' &&
      birthdate &&
      newValue
    ) {
      const storedDate = dayjs(birthdate, 'DD.MM.YYYY');
      const currentDate = dayjs();

      const storedAge = currentDate.diff(storedDate, 'year');

      if (storedAge < minAge) {
        const enteredAge = currentDate.diff(newValue, 'year');
        const birthdayThisYear = newValue.add(enteredAge, 'year');
        const isBeforeBirthday = currentDate.isBefore(birthdayThisYear);

        const adjustedEnteredAge = isBeforeBirthday
          ? enteredAge - 1
          : enteredAge;

        if (adjustedEnteredAge < minAge) {
          setIsDateValid(false);
          setErrorMessage(`Der VN muss mindestens 18 Jahre alt sein`);
          return;
        }
      } else if (
        storedAge >= minAge &&
        policyHolderIsEqualInsuredPerson === '2'
      ) {
        const enteredAge = currentDate.diff(newValue, 'year');
        const birthdayThisYear = newValue.add(enteredAge, 'year');
        const isBeforeBirthday = currentDate.isBefore(birthdayThisYear);

        const adjustedEnteredAge = isBeforeBirthday
          ? enteredAge - 1
          : enteredAge;

        if (adjustedEnteredAge >= minAge) {
          setIsDateValid(false);
          setErrorMessage(`Der VN darf keine 18 Jahre alt sein`);
          return;
        }
      }
    }
  };

  const renderCustomDay = (
    day: Dayjs,
    selectedDays: Dayjs[],
    pickersDayProps: PickersDayProps<Dayjs>
  ) => {
    const { children, ...restProps } = pickersDayProps;
    return (
      <PickersDay
        {...restProps}
        sx={{ fontSize: '13px' }}
        onClick={() => handleDateChange(day)}
      >
        {children}
      </PickersDay>
    );
  };

  return (
    <QuestionLayout question={question} meta={meta}>
      <FormControl
        fullWidth
        error={meta.touched && Boolean(meta.error)}
        hiddenLabel
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            inputFormat="DD.MM.YYYY"
            disabled={question.readonly}
            {...field}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                variant="filled"
                {...params}
                helperText={errorMessage ? errorMessage : ''}
                error={!isDateValid}
              />
            )}
            renderDay={renderCustomDay}
          />
        </LocalizationProvider>
      </FormControl>
      <Grid item paddingLeft={'20px'}>
        {question.answer.prefilled && !question.readonly && (
          <TooltipReusable
            title={[
              'Diese Angabe wurde aus Ihren vorherigen Angaben übernommen. Bitte überprüfen Sie diese auf Korrektheit.',
            ]}
            iconFlag={false}
          />
        )}
      </Grid>
    </QuestionLayout>
  );
}
