import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import InsuranceStep from '../InsuranceStep';
import { Divider } from '@mui/material';

const VMKCard: React.FC = () => {
  return (
    <Card
      sx={{
        position: 'relative',
        zIndex: (theme) => theme.zIndex.modal - 1,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        marginY: '10px',
      }}
    >
      <InsuranceStep />
      <Divider />

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginY: '20px',
          padding: 'unset',
        }}
      >
        <Typography variant="h5" gutterBottom marginBottom={'20px'}>
          Informationen zum Online-Abschluss
        </Typography>
        <Typography
          variant="body1"
          paragraph
          marginRight={{ sm: '20px', lg: '30px' }}
        >
          Eine Rückdatierung auf den 01. des Monats ist bis zum 15. des Monats
          möglich. Online Abschlüsse sind möglich für:
          <br />
          <br />
          Erwachsene ab 18 Jahren (Versicherungsnehmer*in = versicherte Person)
          <br />
          Kinder bis 15 Jahre (abweichende(r) Versicherungsnehmer*in)
          <br />
          Online Abschlüsse für 16 und 17 Jährige sind aktuell nicht möglich
          (PDF-Antrag nutzen)
        </Typography>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default VMKCard;
