import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useField } from 'formik';
import QuestionLayout from './QuestionLayout';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/utils/hooks';
import { updateChildVisibility } from 'src/store/reducers/riskCheck.reducer';
import mandants from 'src/config/mandant';
import dayjs from 'dayjs';

interface Props {
  question: Question;
  riskIdx: number;
  personIdx: number;
}

export default function RadioQuestion({ question, riskIdx, personIdx }: Props) {
  const dispatch = useAppDispatch();

  const { mandant } = useAppSelector((state) => state.config);
  type Mandant = keyof typeof mandants;
  const birthdateValidation: boolean =
    mandants[mandant as Mandant]?.birthdateValidation;

  const [field, meta, helpers] = useField(
    question.key.replace(/\./g, '&').replace(/\[\]/g, '$')
  );

  const birthdate = localStorage.getItem('birthdate');
  const minAge = 18;
  const storedDate = dayjs(birthdate, 'DD.MM.YYYY');
  const currentDate = dayjs();

  const storedAge = currentDate.diff(storedDate, 'year');

  const [isDisabled, setisDisabled] = useState(false);

  useEffect(() => {
    if (
      birthdateValidation &&
      question.key === 'policyHolderIsEqualInsuredPerson'
    ) {
      setisDisabled(true);
      if (storedAge < minAge) {
        helpers.setValue('false');
      } else {
        helpers.setValue('true');
      }
    }
  }, []);

  useEffect(() => {
    const { value } = field;
    localStorage.setItem('policyHolderIsEqualInsuredPerson', value);
    if (value !== undefined && value !== null && value !== '') {
      const regex = new RegExp(question.expChild);
      const isMatch = regex.test(field.value);
      const visible = question.expChildCheckPositive === isMatch;
      const key = question.key;
      dispatch(
        updateChildVisibility({
          value,
          visible,
          key,
          riskIdx,
          personIdx: personIdx - 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);
  return (
    <QuestionLayout question={question} meta={meta}>
      <Box>
        {question.answer.picks?.length && (
          <FormControl error={meta.touched && Boolean(meta.error)}>
            <RadioGroup row>
              {question.answer.picks.map((p, index) => (
                <FormControlLabel
                  key={`${p.id}${index}`}
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 24,
                    },
                  }}
                  {...field}
                  checked={
                    (field.value && p.id === field.value) ||
                    (!field.value && p.id === question.answer.value)
                  }
                  value={p.id}
                  control={
                    <Radio
                      disabled={isDisabled ? true : question.readonly}
                      onChange={(e) => {
                        if (e.target.checked) {
                          helpers.setValue(e.target.value);
                        } else {
                          helpers.setValue('');
                        }
                      }}
                    />
                  }
                  label={p.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )}
      </Box>
    </QuestionLayout>
  );
}
