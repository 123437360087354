import { Grid, Typography, Link } from '@mui/material';
import { useState } from 'react';
import NoticeDialogContract from 'src/components/NoticeDialogContract';
import mandants from 'src/config/mandant';
import { useAppSelector } from 'src/utils/hooks';

export default function CorrectInformationOnly() {
  const [open, setOpen] = useState(false);
  const { mandant } = useAppSelector((state) => state.config);
  type MandantKey = keyof typeof mandants;
  const { dataprotectionC } = mandants[mandant as MandantKey]?.footer;
  const { dataprotText } = mandants[mandant as MandantKey]?.footer;
  return (
    <>
      <NoticeDialogContract open={open} setOpen={setOpen} />
      <Grid>
        <Typography variant="body1">
          Ich bestätige, die{' '}
          <Link href={dataprotectionC} target="_blank" underline="hover">
            {dataprotText}
          </Link>{' '}
          gelesen zu haben und erkläre mich damit einverstanden und bestätige, dass ich bei einem Träger der gesetzlichen Krankenversicherung (GKV) versichert bin bzw. dort ein Anspruch auf Familienversicherung besteht oder Anspruch auf freie Heilfürsorge besteht.
        </Typography>
      </Grid>
    </>
  );
}
